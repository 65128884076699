export default class GenericField {
  constructor(name, label, filterParams) {
    this.name = name;
    this.label = label;
    // for filter
    const { placeholder = '' } = filterParams;
    this.key = name;
    this.placeholder = placeholder;
  }

  forPresenter = () => {
    throw new Error('Called superclass');
  }

  forFilterCast = () => {
    throw new Error('Called superclass');
  }

  forFilterRules = () => {
    throw new Error('Called superclass');
  }

  forFormCast = () => {
    throw new Error('Called superclass');
  }

  forFormRules = () => {
    throw new Error('Called superclass');
  }

  forFilterInitialValue = () => {
    throw new Error('Called superclass');
  }

  forFormInitialValue = () => {
    throw new Error('Called superclass');
  }
}
