import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';

export default class StringField extends GenericField {
  constructor(
    name,
    label,
    {
      required = false,
    } = {},
    filterParams = {},
  ) {
    super(name, label, filterParams);

    this.type = 'search';
    this.required = required;
  }

  forPresenter = (value) => value

  forFormInitialValue = (value) => value

  forFilterInitialValue = (value) => value

  forFilterRules = () => undefined

  forFormRules = () => {
    const output = [];

    if (this.required) {
      output.push({
        required: !!this.required,
        message: 'Acest camp este obligatoriu',
      });
    }

    return output;
  }

  forFormCast = () => {
    const yupChain = yup
      .string()
      .nullable(true)
      .trim()
      .label(this.label);

    return yupChain;
  }

  forFilterCast = () => yup
    .string()
    .nullable(true)
    .trim()
    .label(this.label)
}
